import { ExecuteQueryResult } from "./playByPlayResult";
import { createParser } from 'eventsource-parser';

export interface Plan {
    eventIds: number[];
    mainQuery: string;
    fetchTeamStrength: boolean;
}

export interface PlanResponse {
    plan: Plan,
    estimatedDuration: string
};

export enum SortDirection {
    None = 0,
    Asc, Desc
}

export interface PostProcessingOptions {
    limit?: number,
    sortDirection: SortDirection,
    sortField?: string
}

export async function plan(accessToken: string, matchQuery: Object, eventQuery: Object, ppOptions: PostProcessingOptions): Promise<PlanResponse> {
    const mqString = JSON.stringify(matchQuery);
    const eqString = JSON.stringify(eventQuery);

    const res = await fetch('/api/query/plan', {
        body: JSON.stringify({ matchQuery: mqString, eventQuery: eqString, postProcessingOptions: ppOptions }),
        headers: { 'Content-type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
        method: 'POST'
    });

    if (res.status !== 200) {
        throw new Error(res.statusText);
    }
    return await res.json();
}

export function execute(accessToken: string, matchQuery: object, eventQuery: object, ppOptions: PostProcessingOptions): { prom: Promise<ExecuteQueryResult>, controller: AbortController } {
    const mqString = JSON.stringify(matchQuery);
    const eqString = JSON.stringify(eventQuery);

    const controller = new AbortController();

    const prom = fetch('/api/query/query', {
        body: JSON.stringify({ matchQuery: mqString, eventQuery: eqString, postProcessingOptions: ppOptions }),
        headers: { 'Content-type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
        method: 'POST',
        signal: controller.signal
    })
        .then(res => {
            if (res.status !== 200) {
                throw new Error(res.statusText);
            }
            return res.json();
        });

    return { prom, controller };
}

export async function genAiQuery(
    accessToken: string, prompt: string, onChatMsg?: (d: string) => void, onClose?: () => void, onError?: (d: string) => void,
    onQuery?: (d: string) => void): Promise<AbortController> {

    const abortController = new AbortController();

    const es = await fetch(`/api/ai?prompt=${encodeURIComponent(prompt)}`, {
        headers: {
            'Authorization': 'Bearer ' + accessToken,
        }, 
        signal: abortController.signal
    });

    if (!es.ok || !es.body) {
        throw new Error("Failed to fetch ai query response: " + es.statusText);
    }

    const stream = es.body.pipeThrough(new TextDecoderStream());
    const reader = stream.getReader();

    const parser = createParser((ev) => {
        if (ev.type === "event") {
            if (ev.event === 'chatmsg') {
                onChatMsg?.(decodeURIComponent(ev.data.replace(/\+/g, " ")));
            }
            else if (ev.event === 'err' || ev.event === 'error') {
                onError?.(decodeURIComponent(ev.data.replace(/\+/g, " ")));
            }
            else if (ev.event === 'query') {
                onQuery?.(decodeURIComponent(ev.data.replace(/\+/g, " ")));
            }
        }
    });

    const pump = async () => {
        const r = await reader.read();
        if (r.done) {
            onClose?.();
            return;
        }
        if (r.value) {
            parser.feed(r.value);
        }
        await pump();
    }

    pump();

    return abortController;
}
