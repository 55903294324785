import { PlaySquareOutlined } from "@ant-design/icons";
import { Button, Spin, Table, Tag } from "antd";
import useMessage from "antd/es/message/useMessage";
import { ColumnsType } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { BlueLineType, CrossingDirection, CrossingResult, CrossingType, MatchPlayByPlayEventRow, PassResult, PlayByPlayEventType, ShotResult, TeamSide, WisehockeyEvent, WisehockeyPlayer } from "./playByPlayResult";
import { useAuth } from "react-oidc-context";
import { TableRowSelection } from "antd/es/table/interface";

type ResultsTableProps = {
    results: MatchPlayByPlayEventRow[];
    loading: boolean;
    onClipSelectionChange?: ((rows: MatchPlayByPlayEventRow[]) => void);
};

function getEnumKey<T extends {[index:string]:string}>(value: string, e: T): string|undefined {
    return Object.entries(e).find(([_, val]) => val === value)?.[0];
}

function EventTypeTag({ eventType }: { eventType: number }) {
    const typeStr = PlayByPlayEventType[eventType];

    return (<Tag>{typeStr}</Tag>);
}

function ShotResultTag({ shotResult }: { shotResult?: string }) {
    if (!shotResult) {
        return null;
    }
    const resStr = getEnumKey(shotResult, ShotResult);
    return (<Tag>{resStr}</Tag>);
}

function PassResultTag({ passResult }: { passResult?: string }) {
    if (!passResult) {
        return null;
    }
    const resStr = getEnumKey(passResult, PassResult);
    return (<Tag>{resStr}</Tag>);
}

function MatchClock({ sfps }: { sfps: number }) {
    var minutes = Math.floor(sfps / 60);
    var secs = (sfps % 60).toString().padStart(2, "0");

    return (<span>{minutes}:{secs}</span>);
}

function MatchTag({ match }: { match?: WisehockeyEvent }) {
    if (!match) {
        return (<Tag>(unknown)</Tag>);
    }

    if (!!match.name) {
        return (<Tag>{match.name}</Tag>);
    }

    const homeTeamName = match.matchDetails?.homeTeam?.shortName ?? "";
    const awayTeamName = match.matchDetails?.awayTeam?.shortName ?? "";
    const date = new Date((match.startTime?.seconds ?? 0) * 1000);

    return (<Tag>{homeTeamName} - {awayTeamName} {date.toISOString().substring(0, 10)}</Tag>);
}

function PlayerTag({ playerId, roster }: { playerId?: number, roster?: WisehockeyPlayer[] }) {
    if (!playerId) {
        return null;
    }

    const player = roster?.find(x => x.id === playerId);
    if (!player) {
        return <span>{playerId}</span>;
    }

    return (
        <Tag>
            #{player.jersey} {player.lastName} {player.firstName}
            <br />{player.team.shortName}
        </Tag>
    );
}

function TeamTag({ teamSide, match }: { teamSide?: TeamSide, match?: WisehockeyEvent }) {
    if (!teamSide) {
        return (null);
    }
    if (!match?.matchDetails) {
        return (<span>{getEnumKey(teamSide, TeamSide)}</span>);
    }

    const team = teamSide === TeamSide.Home ? match.matchDetails.homeTeam : match.matchDetails.awayTeam;

    return (<Tag>{team.shortName}</Tag>);
}

function VideoLink({ match, timestamp }: { match?: WisehockeyEvent, timestamp: number }) {

    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = useMessage();
    const auth = useAuth();

    const fetchLink = async () => {
        if (!match) {
            return null;
        }
        const authHeader = { 'Authorization': 'Bearer ' + auth.user?.access_token };
        const res = await fetch(`/api/videolink?wisehockeyEventId=${match.wisehockeyEventId}&timestamp=${timestamp - 2000}`, { headers: authHeader });
        if (res.status !== 200) {
            throw new Error(res.statusText);
        }
        return await res.text();
    };

    const openVideo = (async () => {
        setLoading(true);
        try {
            const link = await fetchLink();
            if (link) {
                window.open(link, '_blank');
            }
        } catch (e) {
            messageApi.error("Error fetching video link: " + e);
        } finally {
            setLoading(false);
        }
    });

    return loading ? (<span>{contextHolder}<Spin /></span>) : (<span>{contextHolder}<Button type="link" onClick={openVideo}><PlaySquareOutlined /></Button></span>)
}

const commonColumns: ColumnsType<MatchPlayByPlayEventRow> = [
    { title: 'Video', key: 'videoLink', render: (_, row) => (<VideoLink match={row.match} timestamp={row.timestamp} />) },
    { title: 'Type', dataIndex: 'eventType', key: 'eventType', render: (val) => <EventTypeTag eventType={val} /> },
    {
        title: 'Match', dataIndex: 'match', render: (m: WisehockeyEvent) => <MatchTag match={m} />,
        sorter: (a, b) => {
            const dateComp = (a.match?.startTime?.seconds ?? 0) - (b.match?.startTime?.seconds ?? 0);
            if (dateComp === 0) {
                return (a.match?.wisehockeyEventId ?? 0) - (b.match?.wisehockeyEventId ?? 0);
            }
            return dateComp;
        }
    },
    { title: 'Period', dataIndex: 'period', key: 'period' },
    {
        title: 'Match clock', dataIndex: 'secondsFromPeriodStart', key: 'period',
        render: (sfps) => <MatchClock sfps={sfps} />, width: '5em',
        sorter: (a, b) => a.period - b.period
    },
    //{ title: 'Timestamp', dataIndex: 'timestamp', key: 'timestamp' },
    {
        title: 'Team', dataIndex: 'team', render: (teamSide, row) => (<TeamTag teamSide={teamSide} match={row.match} />),
        sorter: (a, b) => {
            const teamA = a.team === TeamSide.Home ? a.match?.matchDetails?.homeTeam : a.match?.matchDetails?.awayTeam;
            const teamB = b.team === TeamSide.Home ? b.match?.matchDetails?.homeTeam : b.match?.matchDetails?.awayTeam;
            if (teamA === undefined) {
                if (teamB === undefined) {
                    return 0;
                }
                return -1;
            }
            if (teamB === undefined) {
                return 1;
            }
            return teamA?.shortName?.localeCompare(teamB?.shortName)
        }
    }
];

const shotColumns: ColumnsType<MatchPlayByPlayEventRow> = [
    { title: 'Shooter', dataIndex: 'shooter', key: 'shooter', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    { title: 'Blocker', dataIndex: 'blocker', key: 'blocker', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    { title: 'Goalkeeper', dataIndex: 'goalkeeper', key: 'goalkeeper', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    {
        title: 'Xg', dataIndex: 'xg', key: 'xg', render: (xgVal?: number) => (xgVal?.toFixed(3)),
        sorter: (a, b) => (a.xg ?? 0) - (b.xg ?? 0)
    },
    {
        title: 'Speed', dataIndex: 'speed', key: 'speed', render: (speedVal?: number) => !!speedVal ? ((speedVal * 3.6).toFixed(1)) + " km/h" : null,
        sorter: (a, b) => (a.speed ?? 0) - (b.speed ?? 0)
    },
    { title: 'Result', dataIndex: 'result', key: 'result', render: (shotResult) => <ShotResultTag shotResult={shotResult} /> },
];

const passColumns: ColumnsType<MatchPlayByPlayEventRow> = [
    { title: 'Passer', dataIndex: 'passer', key: 'passer', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    { title: 'Interceptor', dataIndex: 'interceptor', key: 'interceptor', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    { title: 'Receiver', dataIndex: 'receiver', key: 'receiver', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} />, },
    { title: 'Pass result', dataIndex: 'passResult', key: 'passResult', render: (passResult) => <PassResultTag passResult={passResult} /> },
];

const blcColumns: ColumnsType<MatchPlayByPlayEventRow> = [
    { title: 'Direction', dataIndex: 'crossingDirection', key: 'crossingDirection', render: (dir) => getEnumKey(dir, CrossingDirection), },
    { title: 'Blue line', dataIndex: 'blueLineType', key: 'blueLineType', render: (line) => getEnumKey(line, BlueLineType), },
    { title: 'Crossing type', dataIndex: 'crossingType', key: 'crossingType', render: (crossingType) => getEnumKey(crossingType, CrossingType) },
    { title: 'Crossing result', dataIndex: 'crossingResult', key: 'crossingResult', render: (crossingResult) => getEnumKey(crossingResult, CrossingResult) },
    { title: 'Crossing player', dataIndex: 'crossingPlayer', key: 'crossingPlayer', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} /> },
];

const chanceColumns: ColumnsType<MatchPlayByPlayEventRow> = [
    { title: 'Chance player', dataIndex: 'player', key: 'player', render: (playerId, row) => <PlayerTag playerId={playerId} roster={row.roster} /> },
];

function ResultsTable({ results, loading, onClipSelectionChange }: ResultsTableProps) {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const columns = [...commonColumns];
    if (results.find(x => x.eventType === PlayByPlayEventType.Shot)) {
        columns.push(...shotColumns);
    }
    if (results.find(x => x.eventType === PlayByPlayEventType.Pass)) {
        columns.push(...passColumns);
    }
    if (results.find(x => x.eventType === PlayByPlayEventType.BlueLineCrossing)) {
        columns.push(...blcColumns);
    }
    if (results.find(x => x.eventType === PlayByPlayEventType.Chance)) {
        columns.push(...chanceColumns);
    }

    const rowSel = useMemo<TableRowSelection<MatchPlayByPlayEventRow>>(() => ({ 
        type: "checkbox",
        selectedRowKeys,
        onChange: (selectedKeys, selectedRows) => {
            setSelectedRowKeys(selectedKeys);
            onClipSelectionChange?.(selectedRows);
        },
    }), [selectedRowKeys, setSelectedRowKeys, onClipSelectionChange]);

    useEffect(() => { setSelectedRowKeys([]); }, [results]);

    return (
        <Table 
            loading={loading}
            bordered={true}
            size={"small"}
            dataSource={results}
            columns={columns}
            rowSelection={rowSel}
            rowKey={ev => ev.key}
            scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }} />
    );
}

export default ResultsTable;