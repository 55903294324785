import { Alert, Button, Collapse, Input, InputNumber, Select, Space, Spin, Tabs, TabsProps } from "antd";
import { LeagueData } from "../leagueData";
import PlayByPlayEventQueryBuilder from "./PlayByPlayEventQueryBuilder";
import { buildConfig } from "./playByPlayQueryBuilderConfig";
import { Config, ImmutableTree, Utils as QbUtils } from "@react-awesome-query-builder/antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import { DefaultOptionType } from "antd/es/select";
import { PostProcessingOptions, SortDirection, genAiQuery } from "../api";
import { useAuth } from "react-oidc-context";
const { TextArea } = Input;


const initialQuery: Object = { "and": [{ "==": [{ "var": "eventType" }, "shot"] }] };
const localStorageKey = "previousPlayByPlayQuery";
const emptyTree = QbUtils.loadTree({ "type": "group" });

function PlayerSelector({ leagueData, playerId, onPlayerChange }: { leagueData: LeagueData, playerId?: number, onPlayerChange: (id: number) => void }) {
    const allPlayerOptions = useMemo(() => leagueData.players.map(pl => ({ value: pl.id, label: pl.description })), [leagueData]);
    const playerSearchIndex = useMemo(() => new Fuse(leagueData.players, {
        isCaseSensitive: false,
        keys: ['id', 'lastName', 'firstName', 'jersey']
    }), [leagueData]);

    const searchPlayers = (searchTerm: string | null): DefaultOptionType[] => {
        if (!searchTerm) {
            return allPlayerOptions;
        }
        const fuseRes = playerSearchIndex.search(searchTerm, { limit: 20 });
        const opts = fuseRes.map(x => ({
            value: x.item.id,
            label: x.item.description
        }));
        return opts;
    };

    const [playerOptions, setPlayerOptions] = useState<DefaultOptionType[]>(allPlayerOptions);

    return (<Select style={{ width: '30em' }} dropdownMatchSelectWidth={false} filterOption={false}
        showSearch value={playerId} onChange={onPlayerChange} onSearch={(term) => setPlayerOptions(searchPlayers(term))} options={playerOptions} />);
}

function TeamSelector({ leagueData, teamId, onTeamChange }: { leagueData: LeagueData, teamId?: number, onTeamChange: (id: number) => void }) {
    const teamOptions = useMemo(() => {
        return leagueData.teams.map(td => ({ label: `${td.name} (id ${td.id})`, value: td.id }));
    }, [leagueData]);

    return (<Select style={{ width: '30em' }} dropdownMatchSelectWidth={false} filterOption={true} optionFilterProp="label"
        showSearch value={teamId} onChange={onTeamChange} options={teamOptions} />);
}

function TopEventsPreset({ leagueData, config, onQueryChange, type, createQuery }: {
    leagueData: LeagueData, config: Config, createQuery: (config: Config, count: number, playerId?: number, teamId?: number) => { tree: ImmutableTree, ppOptions: PostProcessingOptions },
    onQueryChange: (tree: ImmutableTree, ppOptions: PostProcessingOptions) => void, type?: 'all' | 'player' | 'team'
}) {
    const [playerId, setPlayerId] = useState<number | undefined>();
    const [teamId, setTeamId] = useState<number | undefined>();
    const [count, setCount] = useState<number | undefined>(5);

    useEffect(() => {
        let { tree, ppOptions } = createQuery(config, count ?? 0, playerId, teamId);
        onQueryChange(tree, ppOptions);
    }, [playerId, teamId, count, createQuery, config, onQueryChange]);

    return (
        <Space direction="vertical">

            {type === 'player' ? (
                <Space direction="horizontal">
                    Player:
                    <PlayerSelector onPlayerChange={setPlayerId} playerId={playerId} leagueData={leagueData} />
                </Space>
            ) : undefined}

            {type === 'team' ? (
                <Space direction="horizontal">
                    Team:
                    <TeamSelector onTeamChange={setTeamId} teamId={teamId} leagueData={leagueData} />
                </Space>
            ) : undefined}

            <Space direction="horizontal">
                Event count:
                <InputNumber min={1} value={count} onChange={(num) => setCount(num ?? undefined)} />
            </Space>

        </Space>);
}

function FastestShotsPreset(props: { leagueData: LeagueData, config: Config, onQueryChange: (tree: ImmutableTree, ppOptions: PostProcessingOptions) => void, type?: 'all' | 'player' | 'team' }) {

    const createQuery = useCallback((config: Config, count: number, playerId?: number, teamId?: number) => {
        let tree: ImmutableTree | undefined;
        if (props.type === 'player' && playerId) {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.speed" }, null] }, { "==": [{ "var": "shot.shooter" }, playerId.toString()] }] }, config);
        } else if (props.type === 'team' && teamId) {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.speed" }, null] }, { "==": [{ "var": "team" }, teamId.toString()] }] }, config);
        } else {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.speed" }, null] }] }, config);
        }

        if (!tree) {
            throw new Error("Preset query creation failed");
        }
        return { tree, ppOptions: { limit: count, sortDirection: SortDirection.Desc, sortField: 'shot.speed' } as PostProcessingOptions };
    }, [props.type]);

    return <TopEventsPreset {...props} createQuery={createQuery} />;
}

function TopXgPreset(props: { leagueData: LeagueData, config: Config, onQueryChange: (tree: ImmutableTree, ppOptions: PostProcessingOptions) => void, type?: 'all' | 'player' | 'team' }) {

    const createQuery = useCallback((config: Config, count: number, playerId?: number, teamId?: number) => {
        let tree: ImmutableTree | undefined;
        if (props.type === 'player' && playerId) {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.xg" }, null] }, { "==": [{ "var": "shot.shooter" }, playerId.toString()] }] }, config);
        } else if (props.type === 'team' && teamId) {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.xg" }, null] }, { "==": [{ "var": "team" }, teamId.toString()] }] }, config);
        } else {
            tree = QbUtils.loadFromJsonLogic({ "and": [{ "==": [{ "var": "eventType" }, "shot"] }, { "!=": [{ "var": "shot.xg" }, null] }] }, config);
        }

        if (!tree) {
            throw new Error("Preset query creation failed");
        }
        return { tree, ppOptions: { limit: count, sortDirection: SortDirection.Desc, sortField: 'shot.xg' } as PostProcessingOptions };
    }, [props.type]);

    return <TopEventsPreset {...props} createQuery={createQuery} />;
}

export function PresetQueries(props: { leagueData: LeagueData, config: Config, onQueryChange: (tree: ImmutableTree, ppOptions: PostProcessingOptions) => void }) {

    const [selection, setSelection] = useState('fastestShots');

    let presetUi: ReactNode = '';

    switch (selection) {
        case 'fastestShots':
            presetUi = (<FastestShotsPreset {...props} />);
            break;
        case 'fastestShotsByPlayer':
            presetUi = (<FastestShotsPreset {...props} type='player' />)
            break;
        case 'fastestShotsByTeam':
            presetUi = (<FastestShotsPreset {...props} type='team' />)
            break;
        case 'topXg':
            presetUi = (<TopXgPreset {...props} />);
            break;
        case 'topXgByPlayer':
            presetUi = (<TopXgPreset {...props} type='player' />)
            break;
        case 'topXgByTeam':
            presetUi = (<TopXgPreset {...props} type='team' />)
            break;
    }


    return (
        <Space direction="vertical">
            <Select value={selection} onChange={setSelection} dropdownMatchSelectWidth={false}>
                <Select.Option value="fastestShots">Fastest shots</Select.Option>
                <Select.Option value="fastestShotsByPlayer">Fastest shots by player</Select.Option>
                <Select.Option value="fastestShotsByTeam">Fastest shots by team</Select.Option>
                <Select.Option value="topXg">Top xG shots</Select.Option>
                <Select.Option value="topXgByPlayer">Top xG shots by player</Select.Option>
                <Select.Option value="topXgByTeam">Top xG shots by team</Select.Option>
            </Select>

            {presetUi}
        </Space>
    );

}

function PlayByPlayAiQueryGenerator({config, onUseQuery}: { config: Config, onUseQuery: (jsonLogic?: string, jumpToAdvanced?: boolean) => void }) {

    const [prompt, setPrompt] = useState<string | undefined>();
    const [aiResponseText, setAiResponseText] = useState("");

    const [errorText, setErrorText] = useState("");
    const [query, setQuery] = useState("");

    const [cancel, setCancel] = useState<AbortController | undefined>();
    const [loading, setLoading] = useState(false);

    const auth = useAuth();

    const run = useCallback(async () => {
        if (!prompt) {
            return;
        }
        setAiResponseText("");
        setErrorText("");
        setQuery("");
        onUseQuery(undefined, false);
        let abortController: AbortController|undefined = undefined;

        try {
            setLoading(true);
            abortController = await genAiQuery(
                auth.user?.access_token ?? "", prompt, (s) => setAiResponseText(x => x + s),
                () => { setCancel(undefined); setLoading(false); },
                (err) => { console.error(err); setErrorText(err); setCancel(undefined); setLoading(false); },
                (q) => {
                    setQuery(q);
                    const [, errors] = QbUtils._loadFromJsonLogic(JSON.parse(q), config);
                    if (errors.length > 0) {
                        setErrorText("Query is invalid:\n" + errors.join(", "));
                    } else {
                        onUseQuery(q, false);  
                    }
                }
            );
            setCancel(abortController);
        } catch (e: any) {
            console.error(e);
            setErrorText(e.message ?? e.toString());
            setCancel(undefined);
            setLoading(false);
        }

        return () => { 
            abortController?.abort();
        };
    }, [prompt, auth, config, onUseQuery]);

    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <TextArea style={{ width: '100%' }} size="large" value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="Prompt"></TextArea>
            <Space>
            <Button type="primary" onClick={run} disabled={!prompt || !!cancel || loading}>Generate</Button>
            {cancel ? <><Button type="default" onClick={() => { cancel?.abort(); setCancel(undefined); setLoading(false); }}>Cancel</Button></> : ''}
            {loading ? <Spin size="default" /> : ''}
            </Space>
            {aiResponseText ? <pre style={{ whiteSpace: 'pre-wrap' }}>{aiResponseText}</pre> : ''}
            {errorText ? <Alert type="error" message={errorText} /> : ''}
            {query ? (<><Collapse><Collapse.Panel key={1} header="Result query"><pre>{query}</pre></Collapse.Panel></Collapse>
                <Button type="primary" onClick={() => onUseQuery(query, true)}>Load</Button></>) : ''}
        </Space>
    );
}


export function PlayByPlayEventQuerySection({ leagueData, onQueryChange: onJsonLogicQueryChange, ppOptions, onPpOptionsChange }: { leagueData: LeagueData, onQueryChange: (jsonLogic: Object | undefined) => void, ppOptions: PostProcessingOptions, onPpOptionsChange: (ppOptions: PostProcessingOptions) => void }) {

    const [config] = useState(() => buildConfig(leagueData));
    const [tree, setTree] = useState<ImmutableTree|undefined>(() => loadInitialQueryTree(config));
    const [activeTab, setActiveTab] = useState('presets');

    const onQueryChange = useCallback((tree: ImmutableTree|undefined, ppOptions?: PostProcessingOptions) => {
        console.log("Query change:", tree, ppOptions);
        setTree(tree);
        onPpOptionsChange(ppOptions ?? { sortDirection: SortDirection.None });
        if (tree) {
            localStorage.setItem(localStorageKey, JSON.stringify(QbUtils.getTree(tree)));
        } else {
            localStorage.removeItem(localStorageKey);
        }
    }, [onPpOptionsChange]);

    const onAiQueryChange = useCallback((jsonLogic?: string, jumpToAdvanced?: boolean) => {
        if (!jsonLogic) {
            setTree(undefined);
            return;
        }
        const t = QbUtils.loadFromJsonLogic(JSON.parse(jsonLogic), config);
        onQueryChange(t, undefined);
        if (jumpToAdvanced) {
            setActiveTab('advanced');
        }
    }, [config, onQueryChange]);

    useEffect(() => {
        if (!tree) {
            onJsonLogicQueryChange(undefined);
            return;
        }
        const jsonLogic = QbUtils.jsonLogicFormat(tree, config);
        if (jsonLogic.errors?.length) {
            onJsonLogicQueryChange(undefined);
        } else {
            onJsonLogicQueryChange(jsonLogic.logic);
        }
    }, [tree, config, onJsonLogicQueryChange, ppOptions]);

    const tabItems: TabsProps['items'] = [
        {
            key: 'presets', label: 'Presets',
            children: (<PresetQueries leagueData={leagueData} config={config} onQueryChange={onQueryChange} />)
        },
        {
            key: 'advanced', label: 'Advanced',
            children: (
                <PlayByPlayEventQueryBuilder tree={tree ?? emptyTree} config={config} onQueryChange={onQueryChange} ppOptions={ppOptions} />)
        },
        {
            key: 'ai', label: 'AI',
            children: <PlayByPlayAiQueryGenerator config={config} onUseQuery={onAiQueryChange} />
        }
    ];

    return (<><Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems}></Tabs></>);
}

function loadInitialQueryTree(config: Config) {
    try {
        const storedQuery = localStorage.getItem(localStorageKey);
        if (storedQuery) {
            const jsonQ = JSON.parse(storedQuery);
            return QbUtils.checkTree(QbUtils.loadTree(jsonQ), config);
        }
    } catch (e) {
        console.error(e);
    }

    return QbUtils.checkTree(QbUtils.loadFromJsonLogic(initialQuery, config) ?? emptyTree, config)
}