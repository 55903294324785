export enum PlayByPlayEventType {
    Shot = 1,
    Pass = 2,
    Faceoff = 3,
    BlueLineCrossing = 4,
    GameObjectContest = 5,
    Stoppage = 6,
    Chance = 7,
}

export enum ShotResult {
    Unknown = 'SHOT_RESULT_UNKNOWN',
    Blocked = 'SHOT_RESULT_BLOCKED',
    Goal = 'SHOT_RESULT_GOAL',
    Missed = 'SHOT_RESULT_MISSED',
    Saved = 'SHOT_RESULT_SAVED',
}

export enum PassResult {
    Unknown = 'PASS_RESULT_UNKNOWN',
    Successful = 'PASS_RESULT_SUCCESSFUL',
    Intercepted = 'PASS_RESULT_INTERCEPTED',
    Failed = 'PASS_RESULT_FAILED',
}

export enum BlueLineType {
    Defense = 'BLUE_LINE_TYPE_DEFENSE',
    Offense = 'BLUE_LINE_TYPE_OFFENSE',
}
export enum CrossingDirection {
    TowardsHome = 'CROSSING_DIRECTION_TOWARDS_HOME',
    TowardsOpponent = 'CROSSING_DIRECTION_TOWARDS_OPPONENT',
}
export enum CrossingType {
    Unknown = 'CROSSING_TYPE_UNKNOWN',
    Pass = 'CROSSING_TYPE_PASS',
    Carry = 'CROSSING_TYPE_CARRY',
    Dump = 'CROSSING_TYPE_DUMP',
    Clear = 'CROSSING_TYPE_CLEAR',
    Offside = 'CROSSING_TYPE_OFFSIDE',
    Icing = 'CROSSING_TYPE_ICING',
    LoosePuck = 'CROSSING_TYPE_LOOSE_PUCK',
    Failed = 'CROSSING_TYPE_FAILED',
}
export enum CrossingResult {
    Unknown = 'CROSSING_RESULT_UNKNOWN',
    None = 'CROSSING_RESULT_NONE',
    PossessionMaintained = 'CROSSING_RESULT_POSSESSION_MAINTAINED',
    PossessionLost = 'CROSSING_RESULT_POSSESSION_LOST',
    PuckDumpedIn = 'CROSSING_RESULT_PUCK_DUMPED_IN',
}


export interface WisehockeyTeam {
    id: number;
    shortName: string;
}

export interface MatchDetails {
    homeTeam: WisehockeyTeam;
    awayTeam: WisehockeyTeam;
}

export interface ProtoTimestamp { seconds: number };

export interface WisehockeyEvent {
    wisehockeyEventId: number;
    name?: string;
    startTime?: ProtoTimestamp;
    matchDetails?: MatchDetails;
}

export interface WisehockeyPlayer {
    id: number;
    jersey: number;
    firstName: string;
    lastName: string;
    team: WisehockeyTeam;
}

export interface MatchRoster {
    wisehockeyEventId: number;
    players: WisehockeyPlayer[];
}

export type MatchPlayByPlayEventRow = {
    key: string;

    match?: WisehockeyEvent;
    roster?: WisehockeyPlayer[];
    originalEvent: MatchPlayByPlayEvent;

    eventType: PlayByPlayEventType;
    timestamp: number;
    endTimestamp?: number;
    period: number;
    secondsFromPeriodStart: number;
    team?: TeamSide,

    // Shot
    shooter?: number;
    blocker?: number;
    goalkeeper?: number;
    speed?: number;
    xg?: number;
    result?: number;

    // Faceoff
    homePlayer?: number,
    awayPlayer?: number,
    faceoffWinnerTeamSide?: TeamSide,

    // Pass
    passer?: number,
    receiver?: number,
    interceptor?: number,
    passResult?: PassResult,

    // BLC
    crossingDirection?: CrossingDirection,
    blueLineType?: BlueLineType,
    crossingType?: CrossingType,
    crossingResult?: CrossingResult,
    crossingPlayer?: number,

    // Contest
    contestWinningTeam?: TeamSide,

    // Chance
    player?: number,

}

export interface MatchPlayByPlayEvent {
    wisehockeyEventId: number;
    event: PlayByPlayEventWrapper;
}
export interface PlayByPlayEventWrapper {
    shot?: any;
    pass?: any;
    faceoff?: any;
    stoppage?: any;
    blueLineCrossing?: any;
    gameObjectContest?: any;
    chance?: any;
}

export interface ExecuteQueryResult {
    events: MatchPlayByPlayEvent[];
    matches: WisehockeyEvent[];
    rosters: MatchRoster[];
}

export enum TeamSide {
    Unknown = 'TEAM_UNKNOWN', Home = 'TEAM_HOME', Away = 'TEAM_AWAY'
}

export function buildPlayByPlayEventRows(res: ExecuteQueryResult): MatchPlayByPlayEventRow[] {
    const rows: MatchPlayByPlayEventRow[] = [];
    for (const ev of res.events) {
        const match = res.matches.find(m => m.wisehockeyEventId === ev.wisehockeyEventId);
        const roster = res.rosters.find(r => r.wisehockeyEventId === ev.wisehockeyEventId)?.players;

        const pbpWrapper = ev.event;
        const pbpEv = pbpWrapper.shot ?? pbpWrapper.pass ?? pbpWrapper.faceoff ?? pbpWrapper.stoppage ?? pbpWrapper.gameObjectContest ?? pbpWrapper.blueLineCrossing ?? pbpWrapper.chance;
        rows.push({
            originalEvent: ev,
            key: `${match?.wisehockeyEventId}-${pbpEv["identifier"]?.["timestamp"]}-${pbpEv["identifier"]?.["key"]?.["eventType"]}`,

            match, roster,
            eventType: pbpEv["identifier"]["key"]["eventType"] as PlayByPlayEventType,
            timestamp: pbpEv["identifier"]["timestamp"] as number,
            period: pbpEv["identifier"]["period"] as number,
            secondsFromPeriodStart: pbpEv["identifier"]["secondsFromPeriodStart"] as number,
            
            team: pbpEv["shootingTeam"] ?? pbpEv["passingTeam"] ?? pbpEv["controllingTeam"] ?? pbpEv["team"],
            shooter: pbpEv["shooterId"] as number,
            blocker: pbpEv["blockerId"] as number,
            goalkeeper: pbpEv["goalkeeperId"] as number,
            speed: pbpEv["speed"] as number,
            xg: pbpEv["xgValue"] as number,
            result: pbpEv["result"] as number,

            homePlayer: pbpEv["homePlayerId"],
            awayPlayer: pbpEv["awayPlayerId"],
            faceoffWinnerTeamSide: pbpEv["winner"],

            passer: pbpEv["passerId"],
            receiver: pbpEv["receiverId"],
            interceptor: pbpEv["interceptorId"],
            passResult: pbpEv["passResult"],

            crossingDirection: pbpEv["crossingDirection"],
            blueLineType: pbpEv["blueLineType"],
            crossingType: pbpEv["crossingType"],
            crossingResult: pbpEv["crossingResult"],
            crossingPlayer: pbpEv["crossingPlayerId"],

            contestWinningTeam: pbpEv["winningTeam"],

            player: pbpEv["playerId"] as number
        });
    }
    return rows;
}