
import { Utils as QbUtils, AntdConfig, ImmutableTree, Query, BuilderProps, Builder, Config, AsyncFetchListValuesResult, Fields } from '@react-awesome-query-builder/antd';
import { Component } from 'react';
import '@react-awesome-query-builder/antd/css/styles.css';
import { Collapse } from 'antd';
import { LeagueData, Player, Team, Tournament } from '../leagueData';
import memoizeOne from 'memoize-one';
import Fuse from 'fuse.js';

const localStorageKey = "previousMatchQuery";

const initialQuery = {
    "and": [
        { "==": [{ "var": "tournament" }, "43"] },
        { "==": [{ "var": "homeTeam" }, "14"] },
    ]
};

interface BuilderState {
    tree: ImmutableTree,
    config: Config
};

interface MatchQueryBuilderProps {
    onQueryChange: (jsonLogic: Object | undefined) => void,
    leagueData: LeagueData
}


class MatchQueryBuilder extends Component<{ onQueryChange: (jsonLogic: Object | undefined) => void, leagueData: LeagueData }, BuilderState> {

    getTournamentOptions = memoizeOne((tourns: Tournament[]) => tourns.map(td => ({ title: `${td.name} (id ${td.id})`, value: td.id.toString() })));
    getTeamOptions = memoizeOne((teams: Team[]) => teams.map(td => ({ title: `${td.name} (id ${td.id})`, value: td.id.toString() })));
    getPlayerOptions = memoizeOne((players: Player[]) => players.map(pl => ({ value: pl.id.toString(), title: pl.description })));
    getPlayerSearchIndex = memoizeOne((players: Player[]) => {
        return new Fuse(players, {
            isCaseSensitive: false,
            keys: ['id', 'lastName', 'firstName', 'jersey']
        });
    });

    searchPlayers = async (searchTerm: string | null): Promise<AsyncFetchListValuesResult> => {
        if (!searchTerm) {
            return {
                values: this.getPlayerOptions(this.props.leagueData.players)
            };
        }
        const fuse = this.getPlayerSearchIndex(this.props.leagueData.players);
        const fuseRes = fuse.search(searchTerm);
        return {
            values: fuseRes.map(x => ({
                value: x.item.id.toString(),
                title: x.item.description
            }))
        };
    }

    searchTeams = async (searchTerm: string | null): Promise<AsyncFetchListValuesResult> => {
        if (!searchTerm) {
            return { values: this.getTeamOptions(this.props.leagueData.teams), hasMore: false };
        }
        return {
            values: this.getTeamOptions(this.props.leagueData.teams)
                .filter(li => li.title?.toLowerCase().includes(searchTerm.toLowerCase()))
        };
    }

    searchTournaments = async (searchTerm: string | null): Promise<AsyncFetchListValuesResult> => {
        if (!searchTerm) {
            return { values: this.getTournamentOptions(this.props.leagueData.tournaments), hasMore: false };
        }
        return {
            values: this.getTournamentOptions(this.props.leagueData.tournaments)
                .filter(li => li.title?.toLowerCase().includes(searchTerm.toLowerCase()))
        };
    }


    buildConfig = () => {
        const defaultFields: Fields = {

            tournament: {
                label: 'Tournament',
                type: 'select',
                fieldSettings: {
                    asyncFetch: this.searchTournaments,
                    showSearch: true, useAsyncSearch: true, showCheckboxes: true
                },
                operators: ['select_equals', 'select_not_equals', 'select_any_in', 'select_not_any_in']
            },

            anyTeam: {
                label: 'Home or away team',
                type: 'select',
                fieldSettings: {
                    asyncFetch: this.searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                    forceAsyncSearch: true
                },
                operators: ['select_equals', 'select_not_equals', 'select_any_in', 'select_not_any_in']
            },

            homeTeam: {
                label: 'Home team',
                type: 'select',
                fieldSettings: {
                    asyncFetch: this.searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                    forceAsyncSearch: true
                },
                operators: ['select_equals', 'select_not_equals', 'select_any_in', 'select_not_any_in']
            },

            awayTeam: {
                label: 'Away team',
                type: 'select',
                fieldSettings: {
                    asyncFetch: this.searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                    forceAsyncSearch: true
                },
                operators: ['select_equals', 'select_not_equals', 'select_any_in', 'select_not_any_in']
            },

            // startDate: {
            //     label: 'Start date (and time)',
            //     type: 'datetime',
            //     excludeOperators: ['is_null', 'is_not_null', 'between', 'not_between']
            // },

            eventId: {
                label: "Event ID", type: 'number',
                operators: ['equal']
            },

            rosterPlayers: {
                label: 'Roster players',
                type: 'multiselect',
                fieldSettings: {
                    asyncFetch: this.searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                    forceAsyncSearch: true
                },
                operators: ['multiselect_contains', 'multiselect_not_contains'],
            }

        };

        let conf: Config = {
            ...AntdConfig,
            fields: defaultFields,
            // types: {
            //     multiselect: {
            //         widgets: {
            //             multiselect: {
            //                 opProps: {
            //                     // @ts-ignore
            //                     multiselect_contains: { label: 'Contains any of' },
            //                     multiselect_not_contains: { label: 'Contains none of' },
            //                     multiselect_equals: { label: 'Contains all of' },
            //                     multiselect_not_equals: { label: 'Does not contain all of' },
            //                 }
            //             }
            //         }
            //     }
            // }
        };

        // @ts-ignore
        //conf.types.multiselect.mainWidget.opProps.multiselect_contains.label = 'Contains any of';
        // @ts-ignore
        //conf.types.multiselect.mainWidget.opProps.multiselect_not_contains.label = 'Contains none of';

        conf.settings.groupActionsPosition = 'topLeft';
        conf.settings.valueSourcesInfo = { value: { label: 'Value' } };

        return conf;
    }

    constructor(props: MatchQueryBuilderProps) {
        super(props);
        const config = this.buildConfig();

        try {
            const storedQuery = localStorage.getItem(localStorageKey);
            if (storedQuery) {
                const jsonQ = JSON.parse(storedQuery);
                this.state = {
                    tree: QbUtils.checkTree(QbUtils.loadTree(jsonQ), config),
                    config
                };
                return;
            }
        } catch (e) {
            console.error(e);
        }

        this.state = {
            config: config,
            tree: QbUtils.checkTree(QbUtils.loadFromJsonLogic(initialQuery, config) ?? QbUtils.loadTree({ "type": "group" }), config),
        };
    }

    override componentDidMount(): void {
        const jsonLogic = QbUtils.jsonLogicFormat(this.state.tree, this.state.config);
        if (jsonLogic.errors?.length) {
            this.props.onQueryChange(undefined);
        } else {
            this.props.onQueryChange(jsonLogic.logic);
        }


    }

    render = () => (
        <div>
            <Query {...this.state.config} value={this.state.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />
            {this.renderResult(this.state)}
        </div>
    )

    renderBuilder = (props: BuilderProps) => (
        <div className="query-builder-container" style={{ padding: '1em' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    renderResult = (state: BuilderState) => (
        <div className="query-builder-result">
            <Collapse size="small">
                <Collapse.Panel key="query" header="Built JsonLogic query">
                    <pre>
                        {JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config).logic)}
                    </pre>
                </Collapse.Panel>
            </Collapse>
        </div>
    )

    onChange = (tree: ImmutableTree, config: Config) => {
        this.setState({ tree, config });
        localStorage.setItem(localStorageKey, JSON.stringify(QbUtils.getTree(tree)));
        const jsonLogic = QbUtils.jsonLogicFormat(tree, config);
        if (jsonLogic.errors?.length) {
            this.props.onQueryChange(undefined);
        } else {
            this.props.onQueryChange(jsonLogic.logic);
        }
    }
}

export default MatchQueryBuilder;