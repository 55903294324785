
import { Utils as QbUtils, ImmutableTree, Query, BuilderProps, Builder, Config } from '@react-awesome-query-builder/antd';
import { useCallback, useState } from 'react';
import '@react-awesome-query-builder/antd/css/styles.css';
import { Collapse, InputNumber, Radio, Select, Space, Switch } from 'antd';
import { PostProcessingOptions, SortDirection } from '../api';


interface PlayByPlayEventQueryBuilderProps {
    tree: ImmutableTree,
    onQueryChange: (tree: ImmutableTree, ppOptions: PostProcessingOptions) => void,
    config: Config,
    ppOptions: PostProcessingOptions
}

function PostProcessOptionsSelector({ opts, onChange }: { opts: PostProcessingOptions, onChange: (opts: PostProcessingOptions) => void }) {
    const [limit, setLimit] = useState(opts.limit ?? 5);

    return (
        <Space direction='vertical'>
            <Space>
                Sort
                <Radio.Group
                    value={opts.sortDirection}
                    onChange={v => {
                        onChange({ ...opts, sortDirection: v.target.value });
                    }}
                    optionType="button">
                    <Radio value={0}>None</Radio>
                    <Radio value={1}>Asc</Radio>
                    <Radio value={2}>Desc</Radio>
                </Radio.Group>
                <Select
                    dropdownMatchSelectWidth={false} disabled={opts.sortDirection === SortDirection.None}
                    value={opts.sortField} onChange={f => onChange({ ...opts, sortField: f })}>

                    <Select.Option value="shot.speed">Shot speed</Select.Option>
                    <Select.Option value="shot.xg">Shot xG</Select.Option>
                </Select>
            </Space>
            <Space>Limit
                <Switch checked={opts.limit !== undefined} onChange={(en) => {
                    const newOpts = {
                        ...opts,
                        limit: en ? opts.limit ?? limit : undefined
                    };
                    return onChange(newOpts);
                }} />
                <InputNumber value={limit} onChange={(num) => {
                    setLimit(num ?? 0);
                    if (opts.limit !== undefined) {
                        onChange({ ...opts, limit: num ?? 0 })
                    }
                }} min={1} defaultValue={5} disabled={opts.limit === undefined} />
            </Space>

        </Space>
    );
}


export function PlayByPlayEventQueryBuilder(props: PlayByPlayEventQueryBuilderProps) {
    const renderBuilder = useCallback((props: BuilderProps) => (
        <div className="query-builder-container" style={{ padding: '1em' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    ), []);

    const renderResult = useCallback((tree: ImmutableTree, config: Config) => (
        <div className="query-builder-result">
            <Collapse size="small">
                <Collapse.Panel key="query" header="Built JsonLogic query">
                    <pre>
                        {JSON.stringify(QbUtils.jsonLogicFormat(tree, config).logic)}
                    </pre>
                </Collapse.Panel>
            </Collapse>
        </div>
    ), []);

    return (
        <Space style={{ width: '100%' }} direction='vertical'>
            <Query {...props.config} value={props.tree} onChange={(tree) => props.onQueryChange(tree, props.ppOptions)} renderBuilder={renderBuilder} />
            <PostProcessOptionsSelector onChange={(o) => props.onQueryChange(props.tree, o)} opts={props.ppOptions} />
            {renderResult(props.tree, props.config)}
        </Space>
    );
}

export default PlayByPlayEventQueryBuilder;