
export interface Tournament {
    id: number;
    name: string;
}

export interface Team {
    id: number;
    name: string;
}


export interface Player {
    id: number;
    jersey: number;
    firstName: string;
    lastName: string;

    description: string;
}

export type LeagueData = {
    tournaments: Tournament[],
    teams: Team[],
    players: Player[]
};

export async function fetchLeagueData(accessToken: string): Promise<LeagueData> {

    const authHeaders = {
        'Authorization': 'Bearer ' + accessToken
    };

    const fetchTournaments = fetch('/api/leaguedata/tournaments', { headers: authHeaders }).then(r => {
        if (!r.ok) {
            throw new Error("Fetching data failed: " + r.statusText);
        }
        return r.json();
    });
    const fetchPlayers = fetch('/api/leaguedata/players', { headers: authHeaders }).then(r => {
        if (!r.ok) {
            throw new Error("Fetching data failed: " + r.statusText);
        }
        return r.json();
    }).then((pls: Player[]) => {
        return pls.map(pl => ({ ...pl, description: `${pl.lastName} ${pl.firstName} ${pl.jersey} (${pl.id})` }))
    });
    const fetchTeams = fetch('/api/leaguedata/teams', { headers: authHeaders }).then(r => {
        if (!r.ok) {
            throw new Error("Fetching data failed: " + r.statusText);
        }
        return r.json();
    });

    const [tournaments, players, teams] = await Promise.all([fetchTournaments, fetchPlayers, fetchTeams]);

    return { tournaments, teams, players };
}