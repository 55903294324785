import { AntdConfig } from "@react-awesome-query-builder/antd";
import { AsyncFetchListValuesResult, Fields } from "@react-awesome-query-builder/core";
import { BasicConfig } from "@react-awesome-query-builder/ui";
import Fuse from "fuse.js";
import memoizeOne from "memoize-one";
import { LeagueData, Team, Player } from "../leagueData";

export function buildConfig(leagueData: LeagueData) {
    const getTeamOptions = memoizeOne((teams: Team[]) => {
        console.log("generating team options");
        return teams.map(td => ({ title: `${td.name} (id ${td.id})`, value: td.id.toString() }));
    });
    const getPlayerOptions = memoizeOne((players: Player[]) => players.map(pl => ({ value: pl.id.toString(), title: pl.description })));
    const getPlayerSearchIndex = memoizeOne((players: Player[]) => {
        return new Fuse(players, {
            isCaseSensitive: false,
            keys: ['id', 'lastName', 'firstName', 'jersey']
        });
    });

    const searchPlayers = async (searchTerm: string | null): Promise<AsyncFetchListValuesResult> => {
        if (!searchTerm) {
            return {
                values: getPlayerOptions(leagueData.players)
            };
        }
        const fuse = getPlayerSearchIndex(leagueData.players);
        const fuseRes = fuse.search(searchTerm);
        return {
            values: fuseRes.map(x => ({
                value: x.item.id.toString(),
                title: x.item.description
            }))
        };
    };

    const searchTeams = async (searchTerm: string | null): Promise<AsyncFetchListValuesResult> => {
        if (!searchTerm) {
            return { values: getTeamOptions(leagueData.teams), hasMore: false };
        }
        return {
            values: getTeamOptions(leagueData.teams)
                .filter(li => li.title?.toLowerCase().includes(searchTerm.toLowerCase()))
        };
    };

    const defaultFields: Fields = {
        eventType: {
            label: 'Event type',
            type: 'select',
            fieldSettings: {
                listValues: [
                    { value: 'shot', title: 'Shot' },
                    { value: 'pass', title: 'Pass' },
                    { value: 'blueLineCrossing', title: 'Blue line crossing' },
                    { value: 'puckContest', title: 'Puck Contest' },
                    { value: 'faceoff', title: 'Faceoff' },
                    { value: 'stoppage', title: 'Stoppage' },
                    { value: 'chance', title: 'Chance' },
                ]
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        team: {
            label: 'Team (that performed the event)',
            type: 'select',
            fieldSettings: {
                asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                forceAsyncSearch: true
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        opponentTeam: {
            label: 'Opponent team (that the event is "against")',
            type: 'select',
            fieldSettings: {
                asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                forceAsyncSearch: true
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        homeTeam: {
            label: 'Home team (in the match of the event)',
            type: 'select',
            fieldSettings: {
                asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                forceAsyncSearch: true
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        winnerTeam: {
            label: 'Winner team',
            type: 'select',
            fieldSettings: {
                asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                forceAsyncSearch: true
            }
        },
        anyPlayer: {
            label: 'Player with any role in the event (shooter, passer, goalkeeper, ...)',
            type: 'select',
            fieldSettings: {
                asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                forceAsyncSearch: true
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        eventTeamStrength: {
            label: 'Strength of the team that performed the event',
            type: 'select',
            fieldSettings: {
                listValues: [
                    { value: 'full', title: 'FullStrength' },
                    { value: 'even', title: 'EvenStrength' },
                    { value: 'powerplay', title: 'Powerplay' },
                    { value: 'shorthanded', title: 'Shorthanded' },
                ]
            },
            excludeOperators: ['is_null', 'is_not_null']
        },

        shot: {
            type: '!struct',
            label: 'Shot',
            subfields: {
                xg: {
                    label: 'Shot xG',
                    type: 'number',
                    fieldSettings: { min: 0, max: 1, step: 0.001 },
                    preferWidgets: ['number'],
                    excludeOperators: ['equal', 'not_equal']
                },
                speed: {
                    label: 'Shot speed (m/s)',
                    type: 'number',
                    fieldSettings: { min: 0, max: 60, step: 0.1 },
                    preferWidgets: ['number'],
                    excludeOperators: ['equal', 'not_equal']
                },
                shooter: {
                    label: 'Shooter',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
                blocker: {
                    label: 'Blocker',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
                goalkeeper: {
                    label: 'Goalkeeper',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
                shotResult: {
                    label: 'Shot result',
                    type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'goal', title: 'Goal' },
                            { value: 'saved', title: 'Saved' },
                            { value: 'blocked', title: 'Blocked' },
                            { value: 'missed', title: 'Missed' },
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
            }
        },
        pass: {
            type: '!struct',
            label: 'Pass',
            subfields: {
                passer: {
                    label: 'Passer in a pass',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
                receiver: {
                    label: 'Receiver in a pass',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
                interceptor: {
                    label: 'Interceptor in a pass',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
                passResult: {
                    label: 'Pass result',
                    type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'successful', title: 'Successful' },
                            { value: 'intercepted', title: 'Intercepted' },
                            { value: 'failed', title: 'Failed' },
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
            }
        },

        blueLineCrossing: {
            type: '!struct',
            label: 'Blue line crossing',
            subfields: {
                crossingDirection: {
                    label: "Direction", type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'towardsHome', title: 'Towards home' },
                            { value: 'towardsOpponent', title: 'Towards opponent' }
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
                blueLineType: {
                    label: "Blue line type", type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'defense', title: 'Defense' },
                            { value: 'offense', title: 'Offense' }
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
                crossingType: {
                    label: "Crossing type", type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'unknown', title: 'Unknown' },
                            { value: 'pass', title: 'Pass' },
                            { value: 'carry', title: 'Carry' },
                            { value: 'dump', title: 'Dump' },
                            { value: 'clear', title: 'Clear' },
                            { value: 'offside', title: 'Offside' },
                            { value: 'icing', title: 'Icing' },
                            { value: 'loosePuck', title: 'LoosePuck' },
                            { value: 'failed', title: 'Failed' },
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
                crossingResult: {
                    label: "Crossing result", type: 'select',
                    fieldSettings: {
                        listValues: [
                            { value: 'unknown', title: 'Unknown' },
                            { value: 'none', title: 'None' },
                            { value: 'possessionMaintained', title: 'PossessionMaintained' },
                            { value: 'possessionLost', title: 'PossessionLost' },
                            { value: 'puckDumpedIn', title: 'PuckDumpedIn' },
                        ]
                    },
                    excludeOperators: ['is_null', 'is_not_null']
                },
                crossingPlayer: {
                    label: "Crossing player", type: 'select',
                    fieldSettings: {
                        asyncFetch: searchPlayers, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
            }
        },

        puckContest: {
            type: '!struct',
            label: 'Puck contest',
            subfields: {
                winnerTeam: {
                    label: 'Winner team',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
            }
        },

        faceoff: {
            type: '!struct',
            label: 'Faceoff',
            subfields: {
                winnerTeam: {
                    label: 'Winner team',
                    type: 'select',
                    fieldSettings: {
                        asyncFetch: searchTeams, showSearch: true, useAsyncSearch: true, showCheckboxes: true,
                        forceAsyncSearch: true
                    }
                },
            }
        },

        timestamp: {
            label: 'Timestamp (start)',
            type: 'number',
            fieldSettings: { min: 0 },
            preferWidgets: ['number'],
            excludeOperators: ['is_null', 'is_not_null']
        },
        periodNumber: {
            label: 'Period num',
            type: 'number',
            fieldSettings: { min: 1 },
            preferWidgets: ['number'],
            excludeOperators: ['is_null', 'is_not_null']
        },
        endTimestamp: {
            label: 'End timestamp',
            type: 'number',
            fieldSettings: { min: 0 },
            preferWidgets: ['number']
        },
        secondsFromPeriodStart: {
            label: 'Match clock, seconds from period start',
            type: 'number',
            fieldSettings: { min: 0 },
            preferWidgets: ['number'],
            excludeOperators: ['is_null', 'is_not_null']
        },
        secondsUntilPeriodEnd: {
            label: 'Match clock, seconds until period end',
            type: 'number',
            fieldSettings: { min: 0 },
            preferWidgets: ['number'],
            excludeOperators: ['is_null', 'is_not_null']
        },
    };

    let conf: BasicConfig = {
        ...AntdConfig,

        fields: {
            ...defaultFields,
            surroundingEvents: {
                type: '!struct',
                label: 'Surrounding events',
                subfields: {
                    preceding5s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Preceding events from 5s',
                        subfields: defaultFields,
                    },

                    preceding2s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Preceding events from 2s',
                        subfields: defaultFields
                    },

                    preceding10s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Preceding events from 10s',
                        subfields: defaultFields
                    },

                    following5s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Following events from 5s',
                        subfields: defaultFields
                    },

                    following2s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Following events from 2s',
                        subfields: defaultFields
                    },

                    following10s: {
                        type: '!group',
                        mode: 'some',
                        label: 'Following events from 10s',
                        subfields: defaultFields
                    }
                }
            }
        }
    };

    conf.settings.groupOperators = ['some', 'none'];
    conf.settings.groupActionsPosition = 'topLeft';
    conf.settings.valueSourcesInfo = { value: { label: 'Value' } };

    return conf;
}

