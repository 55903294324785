import { Button, Collapse, Descriptions, Layout, Space, Spin, theme, Divider, Alert, Row, Col } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { fetchLeagueData, LeagueData } from './leagueData';
import MatchQueryBuilder from './queries/MatchQueryBuilder';
import { buildPlayByPlayEventRows, MatchPlayByPlayEventRow } from './playByPlayResult';
import ResultsTable from './ResultsTable';
import { PlayByPlayEventQuerySection } from './queries/PlayByPlayEventQuerySection';
import { PlanResponse, PostProcessingOptions, SortDirection, plan, execute } from './api';
import useMessage from 'antd/es/message/useMessage';
import { AuthProvider, AuthProviderProps, hasAuthParams, useAuth } from 'react-oidc-context';
import { Header } from 'antd/es/layout/layout';
const { Content } = Layout;

type MaybeJsonLogicQuery = Object | null | undefined;

function PlanPreview({ plan, err }: { plan?: PlanResponse, err?: Error }) {
  if (plan) {
    return (
      <div>
        <Space direction='vertical' >
          <Descriptions bordered={true} column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} size="small">
            <Descriptions.Item label="Events">{plan.plan.eventIds.length}</Descriptions.Item>
            <Descriptions.Item label="Will fetch team strength info">{plan.plan.fetchTeamStrength ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Estimated duration">{plan.estimatedDuration}</Descriptions.Item>
          </Descriptions>
          <Collapse size="small">
            <Collapse.Panel key="query" header="Full plan json">
              <pre>
                {JSON.stringify(plan.plan, undefined, 2)}
              </pre>
            </Collapse.Panel>
          </Collapse>
        </Space>
      </div>);
  } else if (err) {
    return <Alert type='error' message='Plan failed' description={err.message} />;
  } else {
    return (<span>No query</span>);
  }
}

function QueryView({ leagueData }: { leagueData: LeagueData }) {
  const { token: { colorBgContainer } } = theme.useToken();

  const [matchQuery, setMatchQuery] = useState<MaybeJsonLogicQuery>(null);
  const [eventQuery, setEventQuery] = useState<MaybeJsonLogicQuery>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [queryError, setQueryError] = useState<Error | undefined>();
  const [queryAbortController, setQueryAbortController] = useState<AbortController | undefined>();
  const [planRes, setPlanRes] = useState<PlanResponse | undefined>();
  const [planError, setPlanError] = useState<Error | undefined>();
  const [resultRows, setResultRows] = useState<MatchPlayByPlayEventRow[] | undefined>();
  const [ppOptions, setPpOptions] = useState<PostProcessingOptions>({ sortDirection: SortDirection.None });
  const auth = useAuth();

  const resultsRef = useRef<HTMLDivElement>(null);

  const runPlan = useCallback(async () => {
    if (!matchQuery || !eventQuery) {
      return;
    }
    setIsLoading(true);
    setPlanRes(undefined);
    setPlanError(undefined);
    try {
      const planRes = await plan(auth.user?.access_token ?? "", matchQuery, eventQuery, ppOptions);
      setPlanRes(planRes);
    } catch (err) {
      setPlanError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [matchQuery, eventQuery, ppOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const runQuery = useCallback(async () => {
    if (!matchQuery || !eventQuery) {
      return;
    }
    setQueryError(undefined);
    setIsLoading(true);
    setResultRows([]);
    try {
      const { prom, controller } = execute(auth.user?.access_token ?? "", matchQuery, eventQuery, ppOptions);
      setQueryAbortController(controller);
      const rows = buildPlayByPlayEventRows(await prom);
      setResultRows(rows);
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch (e) {
      setQueryError(e as Error);
    } finally {
      setIsLoading(false);
    }
  }, [matchQuery, eventQuery, auth, ppOptions]);

  useEffect(() => {
    const updatePlan = (async () => {
      if (!matchQuery || !eventQuery) {
        setPlanRes(undefined);
        return;
      }

      runPlan();
    });
    updatePlan();
  }, [matchQuery, eventQuery, ppOptions, runPlan]);

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ padding: '0 50px', height: '100%' }}>
        <Header className='header' style={{ display: 'flex' }}>
          <div className='logo'><img src="wh_logo.svg" width="5em" height="auto" alt="Wisehockey logo" /> Event Query Tool</div>
          <div className="logged-in-user">Signed in as {auth.user?.profile.name}<Button type='link' onClick={() => { auth.removeUser(); auth.signoutRedirect(); }}>Log out</Button></div>
        </Header>
        <div className="main-content" style={{ background: colorBgContainer, height: '100%' }}>
          <div className="query-section">
            <h2>1. Match selection</h2>
            <p>Select matches that data will be fetched from.</p>
            <p>For faster queries, specify the match selection precisely (minimize the amount of matches).</p>
            <MatchQueryBuilder onQueryChange={setMatchQuery} leagueData={leagueData} />
          </div>

          <Divider />

          <div className="query-section">
            <h2>2. Search for events</h2>
            <p>Set rules for filtering play-by-play events.</p>
            <p>Precise queries will be faster.</p>
            <PlayByPlayEventQuerySection onQueryChange={setEventQuery} leagueData={leagueData} ppOptions={ppOptions} onPpOptionsChange={setPpOptions} />
          </div>

          <Divider />

          <div className="query-section">
            <Row gutter={16}>
              <Col span={6} style={{ textAlign: 'center' }}>
                <Space align='center'>
                  <Button disabled={isLoading || !planRes || planRes.plan.eventIds.length === 0} type="primary" size="large" onClick={runQuery}>
                    Run Query
                  </Button>
                  {isLoading && queryAbortController && <Button onClick={() => queryAbortController?.abort()}>Cancel</Button>}
                  {isLoading && <Spin />}
                </Space>
              </Col>
              <Col span={18}>
                <Space direction='vertical'>
                  <PlanPreview plan={planRes} err={planError} />
                </Space>
              </Col>
            </Row>
            {queryError && <Alert showIcon message="Error executing query" type="error" description={queryError.message} />}
          </div>

          <Divider />

          <h2>Results</h2>
          <div ref={resultsRef}>
            <ResultsTable results={resultRows ?? []} loading={isLoading} />
          </div>


        </div>

      </Content>
    </Layout>
  );
}

function AuthenticatedApp() {
  const [leagueData, setLeagueData] = useState<LeagueData | undefined>();
  const [err, setErr] = useState<Error | undefined>();
  const auth = useAuth();

  useEffect(() => {
    if (!leagueData && auth.user?.access_token) {
      fetchLeagueData(auth.user.access_token)
        .then(meta => setLeagueData(meta))
        .catch(setErr);
    }
  }, [leagueData, auth]);

  if (leagueData) {
    return (<QueryView leagueData={leagueData} />);
  } else if (err) {
    return (
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        <Content style={{ textAlign: 'center', padding: '3em' }}>
          <Alert showIcon message="Error loading league data" type="error" description={err.message} />
        </Content>
      </Layout>
    );
  } else {
    return (
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        <Content style={{ textAlign: 'center', padding: '3em' }}>
          <Spin tip="Loading..." size="large" />
        </Content>
      </Layout>
    );
  }

}

function AppAuth() {
  const auth = useAuth();
  const [loginError, setLoginError] = useState<any | undefined>();

  useEffect(() => {
    if (!loginError && !hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !auth.error) {
      auth.signinRedirect().catch(setLoginError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, loginError]);

  if (auth.activeNavigator === 'signinSilent') {
    return <div>Signing in...</div>;
  }
  if (auth.activeNavigator === 'signoutRedirect') {
    return <div>Signing out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error || loginError) {
    return <div>Login error: {auth.error?.message ?? loginError.message}</div>;
  }

  if (auth.isAuthenticated && !window.frameElement) {
    return <AuthenticatedApp />;
  }

  return <div>???</div>;

}

function App() {
  const [authConfig, setAuthConfig] = useState<AuthProviderProps | undefined>();
  const [messageApi, contextHolder] = useMessage();

  useEffect(() => {
    async function fetchConfig() {
      try {
        const configRes = await fetch('/config.json');
        if (configRes.status !== 200) {
          throw new Error("Failed to load config: " + configRes.statusText);
        }
        const configJson = (await configRes.json() as AuthProviderProps);
        configJson.redirect_uri = configJson.redirect_uri || window.location.origin;
        configJson.silent_redirect_uri = configJson.redirect_uri + '/renew';
        configJson.loadUserInfo = true;

        configJson.onSigninCallback = () => {
          window.history.replaceState({}, document.title, window.location.pathname);
        };
        setAuthConfig(configJson);
      }
      catch (e) {
        messageApi.error(`Error loading config: ${e}`);
      }
    }
    if (!authConfig) {
      fetchConfig();
    }
  }, [authConfig, messageApi]);


  return (<>
    {contextHolder}
    {authConfig
      ? <AuthProvider {...authConfig}> <AppAuth /> </AuthProvider>
      : <Spin size='large'>Loading auth config...</Spin>}
  </>)
}

export default App;
